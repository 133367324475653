import { uuid } from 'vue-uuid';
export const menuItems = [
     {
        id: uuid.v1(),
        label: "menuitems.dashboard.text",
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        isTitle: true
    },
    {
        id: uuid.v1(),
        label: 'menuitems.storage.text',
        icon: 'ri-layout-grid-fill',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.transport.text',
        icon: 'ri-truck-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/transport'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.consolidated.text',
        icon: 'ri-align-top',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/consolidated'
    },
    /* {
        id: uuid.v1(),
        label: 'menuitems.rafc.text',
        icon: 'ri-grid-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/rafc'
    }, */
    {
        id: uuid.v1(),
        label: 'menuitems.rafc-n.text',
        icon: 'ri-checkbox-indeterminate-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/super-congelateur'
    },
    /*{
        id: uuid.v1(),
        label: 'menuitems.health-centers.text',
        icon: 'ri-map-pin-add-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/health-centers'
    },*/
    {
        id: uuid.v1(),
        label: "menuitems.general.text",
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        isTitle: true
    },
    /* {
        id: uuid.v1(),
        label: 'menuitems.equipments.text',
        icon: 'ri-database-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/equipments'
    },*/
    {
        id: uuid.v1(),
        label: 'menuitems.devices.text',
        icon: 'ri-router-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/devices'
    },
    /*{
        id: uuid.v1(),
        label: 'menuitems.contact.text',
        icon: 'ri-phone-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/contact'
    }, */
    {
        id: uuid.v1(),
        label: 'menuitems.alerts.text',
        icon: 'ri-alert-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/alerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.noncalerts.text',
        icon: 'ri-alert-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/nonc-alerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.defecalerts.text',
        icon: 'ri-alert-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/defec-alerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.lastalerts.text',
        icon: 'ri-alert-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/last-alerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.offlineAlerts.text',
        icon: 'ri-alert-fill',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/offline-alerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.logs.text',
        icon: 'ri-menu-fill',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/logs'
    },
    /* {
        id: uuid.v1(),
        label: 'menuitems.offline.text',
        icon: 'ri-signal-wifi-off-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/offline'
    }, */
    {
        id: uuid.v1(),
        label: "menuitems.interventions.text",
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        isTitle: true
    },
    {
        id: uuid.v1(),
        label: 'menuitems.maintenance.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/maintenance'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.interventions.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/interventions'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.interventionsMobile.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/interventions-mobile'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.planifications.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/planifications'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.materials.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/materials'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.centres.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/centres'
    },
    /*{
        id: uuid.v1(),
        label: 'menuitems.offline-e.text',
        icon: 'ri-wireless-charging-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/offline-e'
    },*/
    {
        id: uuid.v1(),
        label: "menuitems.settings.text",
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        isTitle: true
    },
    {
        id: uuid.v1(),
        label: 'menuitems.configsalerts.text',
        icon: 'ri-uninstall-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER'],
        link: '/configsalerts'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.vaccintypes.text',
        icon: 'ri-scan-2-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/vaccins'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.regions.text',
        icon: 'ri-bubble-chart-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/regions'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.provinces.text',
        icon: 'ri-creative-commons-nd-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/provinces'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.states.text',
        icon: 'ri-bill-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/states'
    },/*
    {
        id: uuid.v1(),
        label: 'menuitems.stock-type.text',
        icon: 'ri-stock-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/stock-type'
    },*/
    {
        id: uuid.v1(),
        label: 'menuitems.causes.text',
        icon: 'ri-question-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/causes'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.actions.text',
        icon: 'ri-tools-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/actions'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.marques.text',
        icon: 'ri-question-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER'],
        link: '/marques'
    },
    {
        id: uuid.v1(),
        label: 'menuitems.users.text',
        icon: 'ri-group-line',
        permissions: ['SYSADMIN', 'ADMIN', 'MANAGER'],
        link: '/users'
    },
]